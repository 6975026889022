<template>
  <div class="input-set text-left">
    <label
      class="capitalize font-subtitle2"
      :for="$attrs.name || ''"
      v-if="label != ''"
      >{{ label }}</label
    >
    <component
      :is="`v-${type}`"
      v-model="internalValue"
      v-bind="($props, $attrs)"
      :type="type"
      :showError="error"
      :showSuccess="!error && touched"
      :options="options"
      :isAmount="isAmount"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    />
    <p
      class="hint"
      :class="{ error: error }"
      v-if="hint != ''"
      v-html="hint"
    ></p>
  </div>
</template>
<script>
import TextInput from "@/components/form/TextInput.vue";
import TextareaInput from "@/components/form/TextareaInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import DateSelectInput from "@/components/form/DateSelectInput.vue";
import Checkbox from "@/components/form/Checkbox.vue";

export default {
  name: "InputWrapper",
  components: {
    "v-text": TextInput,
    "v-number": TextInput,
    "v-email": TextInput,
    "v-textarea": TextareaInput,
    "v-password": TextInput,
    "v-select": SelectInput,
    "v-date": DateSelectInput,
    "v-checkbox": Checkbox
  },
  data() {
    return {
      internalValue: this.value
    };
  },
  props: {
    type: {
      type: String,
      required: true
    },
    error: {
      type: Boolean,
      required: false,
      default: false
    },
    touched: {
      type: Boolean,
      required: false,
      default: false
    },
    hint: {
      type: String,
      required: false,
      default: ""
    },
    options: {
      //this should be and array of objects {label: "", value: ""}
      type: Array,
      required: false,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      required: false,
      default: ""
    },
    value: {
      type: [String, Date],
      required: true
    },
    isAmount: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit("input", val);
      this.$emit("changed", val);
    }
  }
};
</script>
