<template>
  <div class="input textarea" :class="{ error: showError }">
    <textarea
      class="w-full"
      v-bind="$attrs"
      name="textarea"
      rows="5"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
    />
    <!-- <img src="@/assets/img/icons/check.svg" alt="check" v-if="showSuccess" /> -->
    <!-- <img src="@/assets/img/icons/X.svg" alt="error" v-if="showError" /> -->
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    showError: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  }
};
</script>
