<template>
  <div class="input">
    <select
      v-bind="$attrs"
      :value="value"
      :name="$attrs.name"
      @input="
        $emit('input', $event.target.value, $event.target.selectedIndex - 1)
      "
    >
      <option disabled selected>{{ $attrs.placeholder }}</option>
      <option
        :value="option.value"
        v-for="option in options"
        :key="option.value"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    showError: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    options: {
      type: Array,
      required: true
    }
  },
  mounted() {
    // console.log("attrsñ", this.$attrs.placeholder);
    // console.log("props", this.$props);
  }
};
</script>
