<template>
  <div class="input" :class="{ error: showError }">
    <span
      class="mx-4 text-gray-700"
      v-if="isAmount"
      v-html="defaultCurrency.htmlEntity"
    ></span>
    <input
      v-bind="$attrs"
      :type="$attrs.type"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    />
    <img
      src="@/assets/img/icons/check-green.svg"
      alt="check"
      v-if="showSuccess"
    />
    <img src="@/assets/img/icons/X.svg" alt="error" v-if="showError" />
  </div>
</template>
<script>
import defaultCurrency from "@/constants/defaultCurrency.js";

export default {
  props: {
    value: {
      type: String,
      required: true
    },
    showError: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    isAmount: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  computed: {
    defaultCurrency() {
      return defaultCurrency;
    }
  }
};
</script>
