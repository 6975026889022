<template>
  <div class="flex flex-col">
    <div :class="{ error: showError }" class="input-set grid-date-inputs">
      <div class="flex flex-col">
        <label for="day">Day</label>
        <input
          name="day"
          :class="{ error: showError || isInvalid }"
          @change="changedInput"
          @input="sanitizeInput"
          v-model="day"
          type="number"
          placeholder="DD"
          class="input"
        />
      </div>
      <div class="flex flex-col">
        <label for="day">Month</label>
        <input
          :class="{ error: showError || isInvalid }"
          @change="changedInput"
          @input="sanitizeInput"
          v-model="month"
          type="number"
          placeholder="MM"
          class="input"
        />
      </div>
      <div class="flex flex-col">
        <label for="year">Year</label>
        <input
          name="year"
          :class="{ error: showError || isInvalid }"
          @change="changedInput"
          @input="sanitizeInput"
          v-model="year"
          type="number"
          placeholder="YYYY"
          class="input"
        />
      </div>
    </div>
    <p v-if="isInvalid" class="mt-2 font-body2 text-red-900">
      Please enter a valid date
    </p>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Date],
      required: true
    },
    showError: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    },
    showSuccess: {
      type: Boolean,
      required: false,
      default() {
        return false;
      }
    }
  },
  data() {
    return {
      day: "",
      month: "",
      year: "",
      isInvalid: false
    };
  },
  watch: {
    value(val) {
      let dateParts = val.split("-");
      this.day = dateParts[0];
      this.month = dateParts[1];
      this.year = dateParts[2];
      if (this.day.length == 1) this.day = `0${this.day}`;
      if (this.month.length == 1) this.month = `0${this.month}`;
      if (
        val !== "" &&
        this.dateIsValid(new Date(`${this.year}-${this.month}-${this.day}`))
      ) {
        this.isInvalid = false;
      } else {
        if (this.day !== "" && this.month !== "" && this.year !== "") {
          this.isInvalid = true;
        }
      }
    }
  },
  methods: {
    sanitizeInput() {
      this.day = this.day.replace(/[^0-9.]+/g, "").substring(0, 2);
      this.month = this.month.replace(/[^0-9.]+/g, "").substring(0, 2);
      this.year = this.year.replace(/[^0-9.]+/g, "").substring(0, 4);
    },
    dateIsValid(date) {
      return date instanceof Date && !isNaN(date);
    },
    changedInput() {
      try {
        let dateParts = this.value.split("-");

        let tmpDay = dateParts[0];
        let tmpMonth = dateParts[1];
        const tmpYear = dateParts[2];
        if (tmpDay.length == 1) tmpDay = `0${tmpDay}`;
        if (tmpMonth.length == 1) tmpMonth = `0${tmpMonth}`;
        if (
          this.dateIsValid(new Date(`${tmpYear}-${tmpMonth}-${tmpDay}`)) ||
          (this.day != "" && this.month != "" && this.year != "")
        ) {
          if (this.day.length == 1) this.day = `0${this.day}`;
          if (this.month.length == 1) this.month = `0${this.month}`;
          if (
            this.dateIsValid(new Date(`${this.year}-${this.month}-${this.day}`))
          ) {
            this.isInvalid = false;
            console.info("date is valid");
          } else {
            console.log(
              "date is invalid",
              `${this.year}-${this.month}-${this.day}`
            );
            if (dateParts.length == 3) {
              this.isInvalid = true;
            }
          }
        }
        this.$emit("changed");
      } catch (error) {
        this.isInvalid = false;
        console.log("incomplete date");
      }
      this.$emit("input", `${this.day}-${this.month}-${this.year}`);
    }
  }
};
</script>
<style lang="postcss">
.grid-date-inputs {
  display: grid;
  grid-template-columns: 60px 60px 71px;
  grid-gap: 24px;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    text-align: center;
  }

  input[type="number"] {
    -moz-appearance: textfield;
    text-align: center;
  }
}
</style>
